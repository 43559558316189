import React, { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Transitions from "./Transitions";
import MainCard from "./MainCard";
import { Badge, IconButton, Grid, Typography, Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCartTwoTone";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CartList from "./CardList";
import { FormattedMessage, useIntl } from "react-intl";
import CardActions from "@mui/material/CardActions";
import { Link } from "@mui/material"
import formatCurrency from "../utils/currencyFormat"
import { calculateIsDarkColor } from "../utils/color";

const CustomizedBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: "0 4px",
    },
}));


const Cart = ({ cartItems = [], setCart, removeFromCart, currency, formID, cartOpen, setCartOpen, language, free, color }) => {


    const intl = useIntl();
    const theme = useTheme();
    const anchorRef = useRef(null);
    const downMD = useMediaQuery(theme.breakpoints.down("md"));

    const [filteredCart, setfilteredCart] = useState('');
    const REACT_APP_WEB_URL = process.env.REACT_APP_WEB_URL;

    const [open, setOpen] = useState(cartOpen);

    useEffect(() => {
        setOpen(cartOpen);
    }, [cartOpen]);

    const handleClick = (event) => {
        setOpen(!open)
    };

    const handleClose = (e) => {
        if (
            (anchorRef.current && anchorRef.current.contains(e.target)) ||
            typeof e.target.className !== "string" ||
            (e.target.className ?? "").includes("no-toggle")
        ) {
            return;
        }
        setOpen(false);
        setCartOpen(false);
    };

    const incrementHandler = (id) => {
        setCart((prevCart) =>
            prevCart.map((item) =>
                item.id === id ? { ...item, quantity: item.quantity + 1 } : item
            )
        );
    };

    const decrementHandler = (id) => {
        setCart((prevCart) =>
            prevCart.map((item) =>
                item.id === id && item.quantity > 1
                    ? { ...item, quantity: item.quantity - 1 }
                    : item
            )
        );
    };

    const totalQuantity = () => {

        return cartItems.reduce((total, item) => {
            const itemTotal = parseFloat(item.price) * item.quantity;
            return total + itemTotal;
        }, 0);
    };
    const totalPrice = totalQuantity();

    // Sepette olan ürünleri istediğim şekilde filtre edip checkout sayfasına göndereceğim

    useEffect(() => {
        const newCart = cartItems.map(item => ({
            id: item.id,
            amount: item.quantity
        }));

        if (cartItems.length > 0) {
            const jsonString = JSON.stringify(newCart);
            const base64String = btoa(jsonString);
            setfilteredCart(base64String)
        }


    }, [cartItems])

    return (
        <>
            <IconButton
                color="inherit"
                ref={anchorRef}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <CustomizedBadge badgeContent={cartItems.length} color="error"
                    sx={{
                        width: '50px',
                        height: '50px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <ShoppingCartIcon sx={{
                        fontSize: "28px"
                    }} />
                </CustomizedBadge>
            </IconButton>


            <Popper
                placement={downMD ? "bottom" : "bottom-end"}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                modifiers={[
                    {
                        name: "offset",
                        options: {
                            offset: [downMD ? 5 : 0, 0],
                        },
                    },
                ]}
                className="widget-block--form--cart--container--popper"

            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions
                            position={downMD ? "top" : "top-right"}
                            in={open}
                            {...TransitionProps}
                        >
                            <Paper>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                >
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12} sx={{ paddingBottom: 2 }}>
                                            <Grid
                                                container
                                                alignItems="center"
                                                justifyContent="space-between"
                                                sx={{ pt: 2, px: 2 }}
                                            >
                                                <Typography variant="span" className="widget-block--form--cart--container--popper--header-title">
                                                    <FormattedMessage id="cart" />
                                                </Typography>
                                                <Typography variant="span" className="widget-block--form--cart--container--popper--header-title">
                                                    <FormattedMessage id="quantity" />
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sx={{
                                            borderTop: "1px solid #ddd",

                                        }}>

                                            <Box className="widget-block--form--cart--container--popper--cartList">
                                                <CartList
                                                    formCurrency={currency}
                                                    data={cartItems}
                                                    removeFromCart={removeFromCart}
                                                    incrementHandler={incrementHandler}
                                                    decrementHandler={decrementHandler}
                                                    language={language}
                                                />
                                            </Box>

                                            <CardActions
                                                sx={{ p: '1em', justifyContent: "space-between", borderTop: "1px solid #ddd", }}
                                            >
                                                <Box>
                                                    {totalPrice > 0 && (
                                                        <>
                                                            <Typography
                                                                variant="span"
                                                                className="widget-block--form--cart--container--popper--footer-title"
                                                            >
                                                                <FormattedMessage id="total" />
                                                            </Typography>
                                                            <Typography
                                                                variant="span"
                                                                className="widget-block--form--cart--container--popper--footer-title"
                                                            >
                                                                {currency && formatCurrency(
                                                                    totalPrice,
                                                                    currency,
                                                                    free,
                                                                    intl
                                                                )}
                                                            </Typography>
                                                        </>

                                                    )}
                                                </Box>

                                                <Link
                                                    target="_blank"
                                                    className="widget-block--form--cart--container--popper--footer-link"
                                                    sx={{
                                                        backgroundColor: color,
                                                        color: calculateIsDarkColor(color) ? "#fff" : "#000",
                                                        "&:hover": {
                                                            opacity: 0.8,
                                                            backgroundColor: calculateIsDarkColor(color)
                                                                ? "#fff"
                                                                : "#000",
                                                            color: !calculateIsDarkColor(color) ? "#fff" : "#000",
                                                        },
                                                        fontSize: "0.875em",
                                                    }}

                                                    href={`${REACT_APP_WEB_URL}/events/register/${formID}?cart=${filteredCart}`}>
                                                    <FormattedMessage id="goToCart" />
                                                </Link>

                                            </CardActions>
                                        </Grid>
                                    </Grid>
                                </MainCard>
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default Cart;
